import React, { useState, useEffect } from "react";
import Head from "next/head";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  AuthProvider,
  getLoginRedirectUrl,
  registerPendingUser,
  registerUser,
  checkIsLoggedIn,
} from "lib/api";
import { Button } from "lib/lucidez";
import { normalizeRedirect } from "lib/utils/redirect";
import { FormUtils } from "lib/utils";
import Divider from "components/core/Divider";

const { normalizeErrorsForForm } = FormUtils;

type Inputs = {
  users: [
    {
      email: string;
      password: string;
    }
  ];
};

const defaultValues: Inputs = {
  users: [
    {
      email: "",
      password: "",
    },
  ],
};

export default function Signup() {
  const { search } = useLocation();
  const [query] = useSearchParams();

  const [isPendingUserRegistration, setIsPendingUserRegistration] =
    useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    setValue,
  } = useForm<Inputs>({
    defaultValues,
  });

  const [working, setWorking] = useState(false);

  useEffect(() => {
    function prefillForm() {
      setIsPendingUserRegistration(true);
      setValue("users.0.email", query.get("email"));
    }

    if (query.has("email") && query.has("token")) {
      prefillForm();
    } else {
      // window.location.href = process.env.NEXT_PUBLIC_NEW_HOST + "/signup";
      window.location.href = "https://sequin.io/signup";
    }
  }, [query]);

  const redirectPath = query.get("redirect_path");

  useEffect(() => {
    const redirectIfLoggedIn = async () => {
      const { ok: loggedIn } = await checkIsLoggedIn();
      if (!loggedIn) {
        return;
      }

      const normalizedRedirect = normalizeRedirect(redirectPath);
      if (normalizedRedirect) {
        window.location.href = normalizedRedirect;
      } else {
        window.location.href = "/";
      }
    };

    redirectIfLoggedIn();
  }, []);

  const joinWithProvider = (provider: AuthProvider) => {
    const normalizedRedirect = normalizeRedirect(redirectPath);
    const url = getLoginRedirectUrl(provider, normalizedRedirect);
    window.location.href = url;
  };

  const joinWithGoogle = () => joinWithProvider(AuthProvider.google);
  const joinWithGithub = () => joinWithProvider(AuthProvider.github);
  const joinWithGitlab = () => joinWithProvider(AuthProvider.gitlab);
  const joinWithMicrosoft = () => joinWithProvider(AuthProvider.microsoft);

  const submitUser = async (fields: any) => {
    setWorking(true);
    try {
      let response;
      if (isPendingUserRegistration) {
        const payload = {
          email: fields.users[0].email,
          password: fields.users[0].password,
          token: query.get("token"),
        };
        response = await registerPendingUser(payload);
      } else {
        response = await registerUser({ user: fields.users[0] });
      }

      if (response.isOk()) {
        const normalizedRedirect = normalizeRedirect(redirectPath);
        if (normalizedRedirect) {
          window.location.href = normalizedRedirect;
        } else {
          window.location.href = "/";
        }
      } else {
        const reason: string =
          response.error.error.summary || "Unknown error, please try again...";
        if (reason.includes("token expired")) {
          toast.error(
            "Your invite token expired, please reach out to your org to resend the invite.",
            { autoClose: 10000, toastId: "signup-invite-token-expired" }
          );
        } else {
          toast.error(<h1 className="font-semibold text-sm p-2">{reason}</h1>, {
            autoClose: 7000,
            toastId: "signup-unknown-error",
          });
        }

        const normalizedErrors = normalizeErrorsForForm(response.error);
        normalizedErrors.forEach(({ name, errors }) => {
          // workaround to fit the backend error onto the signup form
          if (name === "user.email") {
            name = "users.0.email";
          } else if (name === "user.password") {
            name = "users.0.password";
          }

          setError(name as any, {
            message: errors.join(", "),
          });
        });
      }
    } catch (error) {
      // Error
      toast.error("Signup failed, please try again...", {
        toastId: "signup-failure",
      });
      console.error("signup failed", error);
    }

    setWorking(false);
  };

  return (
    <>
      <Head>
        <title>Sign up - Sequin</title>
      </Head>
      <div className="flex flex-col items-center gap-7 w-96">
        <h1 className="text-3xl">Real-time in no time</h1>
        <p className="text-center text-xs text-gray-400">
          By signing up, I agree to Sequin's{" "}
          <a href="https://sequin.io/terms" className="underline font-bold">
            Terms
          </a>{" "}
          and{" "}
          <a href="https://sequin.io/privacy" className="underline font-bold">
            Privacy Policy
          </a>
        </p>
        <section className="flex flex-col gap-3 w-full">
          <Button
            variant="outlined"
            size="xl"
            iconLeft={
              <img
                src="/static/img/login-providers/google-colored-icon.svg"
                alt=""
              />
            }
            className="justify-center w-full"
            onClick={joinWithGoogle}
            iconRight={<></>}
          >
            Sign up with Google
          </Button>
          <Button
            variant="outlined"
            size="xl"
            iconLeft={
              <img src="/static/img/login-providers/github-icon.svg" alt="" />
            }
            className="justify-center w-full"
            onClick={joinWithGithub}
            iconRight={<></>}
          >
            Sign up with GitHub
          </Button>
          <Button
            variant="outlined"
            size="xl"
            iconLeft={
              <img
                src="/static/img/login-providers/gitlab-colored-icon.svg"
                alt=""
              />
            }
            className="justify-center w-full"
            onClick={joinWithGitlab}
            iconRight={<></>}
          >
            Sign up with GitLab
          </Button>
          <Button
            variant="outlined"
            size="xl"
            iconLeft={
              <img
                src="/static/img/login-providers/microsoft-colored-icon.svg"
                alt=""
              />
            }
            className="justify-center w-full"
            onClick={joinWithMicrosoft}
            iconRight={<></>}
          >
            Sign up with Microsoft
          </Button>
        </section>
        <Divider>or sign up through email</Divider>
        <form
          className="w-full flex flex-col gap-2.5"
          onSubmit={handleSubmit(submitUser)}
          autoComplete="off"
        >
          <fieldset>
            <input
              placeholder="Email"
              className="auth-input"
              disabled={isPendingUserRegistration || working}
              {...register("users.0.email", {
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+\.\S+$/i,
                  message: "Please enter a valid email address",
                },
              })}
            />
            <p className="text-red-600 text-xs mt-2">
              <ErrorMessage errors={errors} name="users.0.email" />
            </p>
          </fieldset>
          <fieldset>
            <input
              type="password"
              placeholder="Password"
              className="auth-input"
              disabled={working}
              name="password"
              {...register("users.0.password", {
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password has to be at least 8 characters long",
                },
              })}
            />
            <p className="text-red-600 text-xs mt-2">
              <ErrorMessage errors={errors} name="users.0.password" />
            </p>
          </fieldset>
          <Button
            isLoading={working}
            type="submit"
            variant="primary"
            size="xl"
            className="justify-center"
            iconLeft={<></>}
            iconRight={<></>}
          >
            Sign up
          </Button>
          <p className="text-center">
            Already have an account?{` `}
            <Link
              to={{
                pathname: "/login",
                search,
              }}
            >
              <span className="underline text-black font-bold">Log in</span>
            </Link>
          </p>
        </form>
      </div>
    </>
  );
}
