import React, { useEffect, useState } from "react";
import Head from "next/head";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  checkIsLoggedIn,
  AuthProvider,
  getLoginRedirectUrl,
  loginWithPassword,
} from "lib/api";
import { Button } from "lib/lucidez";
import { useWorkable } from "lib/utils";
import { normalizeRedirect } from "lib/utils/redirect";
import Divider from "components/core/Divider";

type Inputs = {
  email: string;
  password: string;
};

const defaultValues: Inputs = {
  email: "",
  password: "",
};

const sendErrorToast = (message: string = `Unexpected error`) => {
  toast.error(<h1 className="font-semibold text-sm p-2">{message}</h1>, {
    toastId: "login-error",
  });
};

export default function Login() {
  const { search } = useLocation();

  const [query] = useSearchParams();
  const [working, applyWorking] = useWorkable();

  const [isPendingUserRegistration, setIsPendingUserRegistration] =
    useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    defaultValues,
  });

  const redirectPath = isPendingUserRegistration
    ? `/join${search}`
    : query.get("redirect_path");

  useEffect(() => {
    function prefillForm() {
      setIsPendingUserRegistration(true);
      setValue("email", query.get("email"));
    }

    if (query.has("email") && query.has("token")) {
      prefillForm();
    }
  }, [query]);

  useEffect(() => {
    const redirectIfLoggedIn = async () => {
      const { ok: loggedIn } = await checkIsLoggedIn();
      if (!loggedIn) {
        return;
      }

      const normalizedRedirect = normalizeRedirect(redirectPath);
      if (normalizedRedirect) {
        window.location.href = normalizedRedirect;
      } else {
        window.location.href = "/";
      }
    };

    redirectIfLoggedIn();
  }, []);

  const joinWithProvider = (provider: AuthProvider) => {
    const normalizedRedirect = normalizeRedirect(redirectPath);
    const url = getLoginRedirectUrl(provider, normalizedRedirect);
    window.location.href = url;
  };

  const joinWithGoogle = () => joinWithProvider(AuthProvider.google);
  const joinWithGithub = () => joinWithProvider(AuthProvider.github);
  const joinWithGitlab = () => joinWithProvider(AuthProvider.gitlab);
  const joinWithMicrosoft = () => joinWithProvider(AuthProvider.microsoft);

  const handleLogin = async (fields: any) => {
    const { email, password } = fields;
    const res = await applyWorking(() =>
      loginWithPassword(email, password, redirectPath as string | undefined)
    );

    if (res.isErr()) {
      sendErrorToast(res.error.error?.summary);
    } else {
      window.location.href = res.value.url;
    }
  };

  const errorMessage = query.get("errorMessage");
  useEffect(() => {
    if (!errorMessage) {
      return;
    }
    sendErrorToast(errorMessage);
  }, [errorMessage]);

  return (
    <>
      <Head>
        <title>Log in - Sequin</title>
      </Head>
      <div className="flex flex-col items-center gap-7 w-96">
        <h1 className="text-3xl">Log in to Sequin</h1>
        <section className="flex flex-col gap-3 w-full">
          <Button
            variant="outlined"
            size="xl"
            iconLeft={
              <img
                src="/static/img/login-providers/google-colored-icon.svg"
                alt=""
              />
            }
            className="justify-center w-full"
            onClick={joinWithGoogle}
            iconRight={<></>}
          >
            Log in with Google
          </Button>
          <Button
            variant="outlined"
            size="xl"
            iconLeft={
              <img src="/static/img/login-providers/github-icon.svg" alt="" />
            }
            className="justify-center w-full"
            onClick={joinWithGithub}
            iconRight={<></>}
          >
            Log in with GitHub
          </Button>
          <Button
            variant="outlined"
            size="xl"
            iconLeft={
              <img
                src="/static/img/login-providers/gitlab-colored-icon.svg"
                alt=""
              />
            }
            className="justify-center w-full"
            onClick={joinWithGitlab}
            iconRight={<></>}
          >
            Log in with GitLab
          </Button>
          <Button
            variant="outlined"
            size="xl"
            iconLeft={
              <img
                src="/static/img/login-providers/microsoft-colored-icon.svg"
                alt=""
              />
            }
            className="justify-center w-full"
            onClick={joinWithMicrosoft}
            iconRight={<></>}
          >
            Log in with Microsoft
          </Button>
        </section>
        <Divider>or</Divider>
        <form
          className="w-full flex flex-col gap-2.5"
          onSubmit={handleSubmit(handleLogin)}
          autoComplete="off"
        >
          <fieldset>
            <input
              placeholder="Email"
              className="auth-input"
              disabled={working}
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\S+@\S+\.\S+$/i,
                  message: "Please enter a valid email address",
                },
              })}
            />
            <p className="text-red-600 text-xs mt-2">
              <ErrorMessage errors={errors} name="email" />
            </p>
          </fieldset>
          <fieldset>
            <input
              type="password"
              placeholder="Password"
              className="auth-input"
              disabled={working}
              {...register("password", {
                required: "Password is required",
              })}
            />
            <p className="text-red-600 text-xs mt-2">
              <ErrorMessage errors={errors} name="password" />
            </p>
          </fieldset>
          <Button
            isLoading={working}
            type="submit"
            id="login-identity-button"
            variant="primary"
            size="xl"
            className="justify-center"
            iconLeft={<></>}
            iconRight={<></>}
          >
            Log in
          </Button>
          <p className="text-center font-bold">
            <Link to="/login/forgot-password">
              <span className="underline text-black">Forgot password?</span>
            </Link>
          </p>
          <p className="text-center">
            New to Sequin?{` `}
            <a
              href="https://sequin.io/signup"
              className="underline text-black font-bold"
            >
              Create an account
            </a>
          </p>
        </form>
      </div>
    </>
  );
}
